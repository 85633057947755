import React, { useEffect, useState } from 'react';
import { useConnectWallet } from '@web3-onboard/react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Wallet } from '../../onboard/onboard';
import RandomImage from '../common/RandomLogo';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const NewTag = () => {
  return (
      <span className="new-tag">New</span>
  );
};

const Header = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const userData = useSelector((state) => state?.state);

  useEffect(() => {
    const checkBlockedUser = async () => {
      if (wallet) {
        try {
          if (
              wallet?.accounts[0]?.address.toLowerCase() ===
              '0xcC1E00A7EC874282075Ac89Fd42e416294fa1d7b'.toLowerCase() ||
              wallet?.accounts[0]?.address.toLowerCase() ===
              '0xBFf06DEb7059B475a734E71D101b2D9fF3247cFF'.toLowerCase()
          ) {
            setIsAdmin(true);
          } else {
            setIsAdmin(false);
          }
        } catch (error) {
          console.error('Error checking user:', error);
        }
      } else {
        setIsAdmin(false);
      }
    };

    checkBlockedUser();
  }, [wallet, disconnect]);

  return (
      <>
        <header id="header">
          <nav
              data-aos="zoom-out"
              data-aos-delay={800}
              className="navbar gameon-navbar navbar-expand"
          >
            <div className="container header">
              <RandomImage />
              <div className="ml-auto" />
              <ul className="navbar-nav items mx-auto">
                <li className="nav-item">
                  <a href="/" className="nav-link">
                    Home{' '}
                  </a>
                </li>
                <li className="nav-item">
                  <span className="nav-link">
                    <Link to={'https://grnstxcoin.timmy.finance'}>Coin Staking</Link>
                  </span>
                </li>
                <li className="nav-item dropdown">
                  <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="stakingDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                  >
                    Socials
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="stakingDropdown">
                    <li>
                      <Link
                          to="https://twitter.com/greenstixv2 "
                          className="dropdown-item dropdown-item-black"
                      >
                        Twitter
                      </Link>
                    </li>
                    <li>
                      <Link
                          to="https://medium.com/@greenstixinc/whitepaper-greenstix-keeping-it-green-irl-web3-000128770f1e"
                          className="dropdown-item dropdown-item-black"
                      >
                        Medium
                      </Link>
                    </li>
                    <li>
                      <Link
                          to="https://t.me/GreenStixToken"
                          className="dropdown-item dropdown-item-black"
                      >
                        Telegram
                      </Link>
                    </li>
                    <li>
                      <Link
                          to="https://discord.gg/fudm2UDtyc"
                          className="dropdown-item dropdown-item-black"
                      >
                        Discord
                      </Link>
                    </li>
                  </ul>
                </li>
                {isAdmin && (
                    <li className="nav-item">
                  <span className="nav-link">
                    <Link to={'/admin'}>Admin</Link>
                  </span>
                    </li>
                )}
              </ul>
              <ul className="navbar-nav toggle">
                <li className="nav-item">
                  <Link
                      to="#"
                      className="nav-link"
                      data-toggle="modal"
                      data-target="#menu"
                  >
                    <i className="icon-menu m-0"/>
                  </Link>
                </li>
              </ul>
              <ul className="navbar-nav action">
                <Wallet/>
              </ul>
            </div>
          </nav>
        </header>
      </>
  );
};

export default Header;
