import React, { Component } from "react";
import { Link } from "react-router-dom";
import RandomImage from '../common/RandomLogo';

const footerData = {
  img: "/img/logo.png",
  copyright: "©2024 Greenstix, All Rights Reserved By",
  owner: "Greenstix",
  ownerLink: "/",
};

class Footer extends Component {
  state = {
    data: footerData,
  };

  render() {
    const { data } = this.state;
    return (
        <footer className="footer-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-8 text-center">
                {/* Footer Items */}
                <div className="footer-items">
                  {/* Logo */}
                  <RandomImage width={50} height={50} />
                  {/* Copyright Area */}
                  <div className="copyright-area py-4">
                    {data.copyright}{" "}
                    <a href={data.ownerLink} target="_blank" rel="noopener noreferrer">
                      {data.owner}
                    </a>
                  </div>
                </div>
                {/* Scroll To Top */}
                <div id="scroll-to-top" className="scroll-to-top">
                  <a href="#header" className="smooth-anchor">
                    <i className="fa-solid fa-arrow-up" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
    );
  }
}

export default Footer;
