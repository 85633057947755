import React, { useEffect, useState } from "react";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { useEthers, ChainId } from "@usedapp/core";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useConnectWallet, useSetChain } from "@web3-onboard/react";
import MyRouts from "./routers/routes";
import { useMutation } from "@apollo/client";
import { checkUser } from "./graphql/users/userMutations";
import { useDispatch } from "react-redux";
import { saveWallet } from "./redux/action";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import ModalMenu from "./components/Modal/ModalMenu";
import { networkHashMap } from "./components/common/constants";
import Moralis from "moralis";
import "bootstrap/dist/js/bootstrap.js";
import RandomBackground from "./components/common/RandomBackground";
import RandomFavicon from './components/common/RandomFavicon';

function App() {
    const [faviconUrl, setFaviconUrl] = useState('');
    const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
    const [{ connectedChain, chains }] = useSetChain();
    const { activate, chainId } = useEthers();
    const reduxDispatch = useDispatch();
    const [CheckUser] = useMutation(checkUser);

    useEffect(() => {
        const fetchFaviconUrl = async () => {
            const url = await RandomFavicon();
            setFaviconUrl(url);
        };
        fetchFaviconUrl();
    }, []);

    useEffect(() => {
        const setFavicon = () => {
            const link = document.querySelector("link[rel='icon']");
            if (link) {
                link.href = faviconUrl;
            } else {
                const newLink = document.createElement("link");
                newLink.rel = "icon";
                newLink.href = faviconUrl;
                document.head.appendChild(newLink);
            }
        };
        if (faviconUrl) {
            setFavicon();
        }
    }, [faviconUrl]);

    useEffect(() => {
        const initializeMoralis = async () => {
            await Moralis.start({
                apiKey: process.env.REACT_APP_MORALIS_KEY
            });
        };
        initializeMoralis();
    }, []);

    useEffect(() => {
        const handleAccountsChanged = (accounts) => {
            if (accounts.length === 0) {
                console.log('Please connect to an account');
                reduxDispatch(saveWallet({ walletAddress: null }));
            } else {
                console.log('Accounts changed:', accounts);
                reduxDispatch(saveWallet({ walletAddress: accounts[0] }));
            }
        };

        if (window.ethereum) {
            window.ethereum.on("accountsChanged", handleAccountsChanged);
        }

        return () => {
            if (window.ethereum) {
                window.ethereum.removeListener("accountsChanged", handleAccountsChanged);
            }
        };
    }, [reduxDispatch]);

    useEffect(() => {
        const checkWalletConnectSession = async () => {
            if (window.sessionStorage.walletconnect) {
                connect({
                    autoSelect: { label: "walletConnect", disableModals: true },
                });
                const provider = new WalletConnectProvider({
                    qrcode: true,
                    bridge: "https://bridge.walletconnect.org",
                    rpc: {
                        [process.env.REACT_APP_ENVIRONMENT === "DEV"
                            ? ChainId.CronosTestnet
                            : ChainId.Cronos]: process.env.REACT_APP_ENVIRONMENT === "DEV"
                            ? process.env.REACT_APP_CRONOS_TESTNET_RPC
                            : process.env.REACT_APP_CRONOS_RPC,
                    },
                    chainId: process.env.REACT_APP_ENVIRONMENT === "DEV"
                        ? ChainId.CronosTestnet
                        : ChainId.Cronos,
                });
                await provider.enable();
                await activate(provider);
            }
        };
        checkWalletConnectSession();

        if (
            wallet &&
            connectedChain &&
            (process.env.REACT_APP_ENVIRONMENT === "DEV"
                ? ChainId.CronosTestnet
                : ChainId.Cronos) !== networkHashMap[connectedChain?.id]
        ) {
            toast.error(
                process.env.REACT_APP_ENVIRONMENT === "DEV"
                    ? "Connect to Cronos testnet Chain and refresh page"
                    : "Connect to Cronos Chain and refresh page",
                {
                    position: "bottom-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }
            );
        }
    }, [wallet?.accounts, connectedChain?.id, connect, activate]);

    const appStyle = {
        backgroundImage: `url('/img/background4.png')`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
    };

    return (
        <div style={appStyle}>
            <Header />
            <ToastContainer />
            <MyRouts />
            <ModalMenu />
            <Footer />
        </div>
    );
}

export default App;
