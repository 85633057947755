import React, { useState } from 'react';

const RandomFavicon = () => {
    // Array of image URLs
    const images = [
        { url: '/img/logo.png', weight: 500 },
    ];

    // Calculate total weight
    const totalWeight = images.reduce((acc, { weight }) => acc + weight, 0);

    // Function to select a random image URL and alt tag based on weights
    const selectRandomImage = () => {
        let cumulativeWeight = 0;
        const randomNumber = Math.random() * totalWeight;
        let randomIndex;
        for (let i = 0; i < images.length; i++) {
            const { url, weight } = images[i];
            cumulativeWeight += weight;
            if (randomNumber <= cumulativeWeight) {
                randomIndex = i;
                break;
            }
        }
        return images[randomIndex].url;
    };

    const randomImageUrl = selectRandomImage();

    return randomImageUrl;
};

export default RandomFavicon;
