import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useConnectWallet } from "@web3-onboard/react";

const ModalMenu = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [{ wallet }] = useConnectWallet();

  const userData = useSelector((state) => state?.state);

  useEffect(() => {
    if (wallet) {
      if (
          wallet?.accounts[0]?.address.toLowerCase() ===
          '0xcC1E00A7EC874282075Ac89Fd42e416294fa1d7b'.toLowerCase() ||
          wallet?.accounts[0]?.address.toLowerCase() ===
          '0xBFf06DEb7059B475a734E71D101b2D9fF3247cFF'.toLowerCase()
      ) {
        setIsAdmin(true);
      }
    } else {
      setIsAdmin(false);
    }
  }, [wallet]);

  return (
    <div id="menu" className="modal fade p-0">
      <div className="modal-dialog dialog-animated">
        <div className="modal-content h-100">
          <div className="modal-header" data-dismiss="modal">
            Menu <i className="far fa-times-circle icon-close" />
          </div>
          <div className="menu modal-body">
            <div className="row w-100">
              <ul className="navbar-nav items mx-auto">
                <li className="nav-item">
                  <a href="/" className="nav-link">
                    Home
                  </a>
                </li>
                {isAdmin && (
                  <li className="nav-item">
                    <span className="nav-link">
                      <a href="/admin">Admin</a>
                    </span>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalMenu;



